import * as React from "react"
import { Link, graphql } from "gatsby"

import Incident from "../components/incident"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location,  pageContext }) => {
  const posts = data.allMarkdownRemark.nodes
  const first = posts[0]
  const siteTitle = first.frontmatter.Name

  return (
    <Layout  location={location} slug={first.fields.slug} incident={siteTitle}>
      <Seo title="Is This A Cyber Attack?" pageContext={pageContext} />
      <Incident incident={first.frontmatter} />
      <hr id="sep"/>
      <ol id="index" style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.Name
          const verdict = post.frontmatter.Attack || 'No'
          const color = verdict === "No" ? "color-no": "color-yes"

          return (
            <li key={post.Slug}>
             Was <Link className="incident" to={`${post.fields.slug}`} itemProp="url"><span itemProp="headline">{title}</span></Link> A Cyber Attack? <span className={color}>{verdict}</span>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        metaTitle
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___Date], order: DESC }, filter: {frontmatter: { Name: {ne: ""}} }) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          Attack
          Name
          Date
          CyberOperation
          ReasonablyExpectedToCauseDeathInjuryToPeopleObjects
          Group
        }
      }
    }
  }
`
